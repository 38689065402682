import React from 'react';
import './App.css';
import Home from "./Pages/Home";

function App() {
  return (
    <div className="tk-sofia-pro" style={{ height: '100%', backgroundColor: '#fff' }}>
      <Home />
    </div>
  );
}


export default App;
